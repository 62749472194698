import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';


function Paymentsuccess(){
 const mydata = localStorage.getItem('')
  const orderURL = "https://backend.shecosmetiics.com/api/createOrder";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reference = searchParams.get('reference');
  const MySwal = withReactContent(Swal)
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const PostData = localStorage.getItem('shecosmeticsorderData');
  const storedPostData = JSON.parse(PostData);
  storedPostData.squadreference = reference;

const createOrder = async () => {
  if (!storedPostData) {
    Swal.fire({
      text: "Error in recovering information",
      icon: "error",
      showCancelButton: false,
      confirmButtonText: "Okay",
      confirmButtonColor: "#ea3989", 
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/';
      }
    });
  } else {
    try {
      console.log(storedPostData)
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: async () => {
          try {
            const response = await axios.post(orderURL, storedPostData, axiosConfig);
            console.log("response received:", response);
            Swal.fire({
              text: 'Order Completed',
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Okay",
              confirmButtonColor: "#ea3989", 
              allowOutsideClick: false,
              allowEscapeKey: false,
              closeOnCancel: true,
            }).then((result) => {
              if (result.isConfirmed) {
                console.log(response);
                window.location.href = '/';
                localStorage.removeItem('shecosmeticsorderData');
              }
            });
          } catch (error) {
            console.log("AXIOS ERROR:", error);
            Swal.fire({
              text: error.message || "An error occurred",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "Okay",
              confirmButtonColor: "#ea3989", 
              allowOutsideClick: false,
              allowEscapeKey: false,
              closeOnCancel: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = '/';
                localStorage.removeItem('shecosmeticsorderData');
              }
            });
          }
          
          
        },
      });
    } catch (error) {
      console.error('Error creating order:', error);
    }
  }
};

useEffect(() => {
    createOrder();
  }, []);




  
  

      return (
     <div>
       
        <br/>
        <br/>
        <br/>
              </div>
               
        );
 
  };

export default Paymentsuccess;
