import React from 'react';

function Privacy() {
  return (
    <div className="container mt-5">
      <h1>Privacy Policy</h1>
      <p>Welcome to SHE Cosmetics' Privacy Policy page.</p>

      <h2>Third-Party Service for Card Details</h2>
      <p>
        For your security and privacy, we use a trusted third-party service to handle your card details during transactions.
        This ensures that your sensitive financial information is kept safe and secure.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We collect the following information for record-keeping and delivery purposes:
      </p>
      <ul>
        <li>Full Name</li>
        <li>Email Address</li>
        <li>House Address</li>
        <li>Phone Number</li>
      </ul>

      <h2>Refund and Exchange Policy</h2>
      <p>
        Please note that we do not offer refunds. However, we do provide exchanges under plausible circumstances.
        If you have any issues with your purchase, please contact our customer service team for assistance.
      </p>

      <h2>Delivery Policy</h2>
      <p>
        Delivery will only be processed once payment is approved. Our delivery process typically takes approximately 24-48 hours after payment confirmation.
        We strive to ensure that your products are delivered to you promptly and in perfect condition.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about our privacy policy, please feel free to contact us at:
        <a href="mailto:info@shecosmetics.com">info@shecosmetics.com</a>.
      </p>
    </div>
  );
}



export default Privacy;
